var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import RcCheckbox from 'rc-checkbox';
import { GroupContext } from './Group';
import { ConfigContext } from '../config-provider';
import devWarning from '../_util/devWarning';
const InternalCheckbox = (_a, ref) => {
    var { prefixCls: customizePrefixCls, className, children, indeterminate = false, style, onMouseEnter, onMouseLeave } = _a, restProps = __rest(_a, ["prefixCls", "className", "children", "indeterminate", "style", "onMouseEnter", "onMouseLeave"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const checkboxGroup = React.useContext(GroupContext);
    const prevValue = React.useRef(restProps.value);
    React.useEffect(() => {
        checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.registerValue(restProps.value);
        if (process.env.NODE_ENV !== 'production')
            devWarning('checked' in restProps || !!checkboxGroup || !('value' in restProps), 'Checkbox', '`value` is not a valid prop, do you mean `checked`?');
    }, []);
    React.useEffect(() => {
        if (restProps.value !== prevValue.current) {
            checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.cancelValue(prevValue.current);
            checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.registerValue(restProps.value);
        }
        return () => checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.cancelValue(restProps.value);
    }, [restProps.value]);
    const prefixCls = getPrefixCls('checkbox', customizePrefixCls);
    const checkboxProps = Object.assign({}, restProps);
    if (checkboxGroup) {
        checkboxProps.onChange = (...args) => {
            if (restProps.onChange) {
                restProps.onChange(...args);
            }
            if (checkboxGroup.toggleOption) {
                checkboxGroup.toggleOption({ label: children, value: restProps.value });
            }
        };
        checkboxProps.name = checkboxGroup.name;
        checkboxProps.checked = checkboxGroup.value.indexOf(restProps.value) !== -1;
        checkboxProps.disabled = restProps.disabled || checkboxGroup.disabled;
    }
    const classString = classNames({
        [`${prefixCls}-wrapper`]: true,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-wrapper-checked`]: checkboxProps.checked,
        [`${prefixCls}-wrapper-disabled`]: checkboxProps.disabled,
    }, className);
    const checkboxClass = classNames({
        [`${prefixCls}-indeterminate`]: indeterminate,
    });
    return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={classString} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <RcCheckbox {...checkboxProps} prefixCls={prefixCls} className={checkboxClass} ref={ref}/>
      {children !== undefined && <span>{children}</span>}
    </label>);
};
const Checkbox = React.forwardRef(InternalCheckbox);
Checkbox.displayName = 'Checkbox';
export default Checkbox;
